import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

	{
		path: '/login',
		name: 'login',
		component: () => import('../components/Login.vue')
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import('../components/ForgotPassword.vue')
	},
	{
		path: '/',
		name: 'Dashboard',
		meta: {
			auth: true
		},
		component: () => import('../components/Dashboard.vue')
	}
	
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }
  next()
})


export default router
