import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://pwa.ctpestsolutions.com/backend/public/api/'

export default new Vuex.Store({
  state: {
    user: null
  },

  mutations: {
    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },
	
	setRequest () {
      //console.log('data recieved');
    },

    clearUserData () {
      localStorage.removeItem('user')
      location.reload()
    }
  },

  actions: {
    login ({ commit }, credentials) {
      return axios
        .post('/login', credentials)
        .then(({ data }) => {
          commit('setUserData', data)
        })
    },
	
	getWorkOrder ({ commit }, postData) {
      return axios
        .post('/fetch_work_order', {
				token: axios.defaults.headers.common.Authorization,
				service_route_id: postData.service_route_id,
				s_date: (postData.s_date) ? postData.s_date : null,
				e_date: (postData.e_date) ? postData.e_date : null,
				d_label: (postData.d_label) ? postData.d_label : null
			})
        .then(({ data }) => {
			commit('setRequest')
          return data;
        })
    },
    retWorkOrder ({ commit }, postData) {
      return axios
        .post('/retrieve_work_order', {
				token: axios.defaults.headers.common.Authorization,
				service_route_id: postData.service_route_id,
				s_date: (postData.s_date) ? postData.s_date : null,
				e_date: (postData.e_date) ? postData.e_date : null,
				d_label: (postData.d_label) ? postData.d_label : null
			})
        .then(({ data }) => {
			commit('setRequest')
          return data;
        })
    },
	
	getServices () {
      return axios
        .post('/fetch_services', {
				token: axios.defaults.headers.common.Authorization
			})
        .then(({ data }) => {
          return data;
        })
    },
	
	getCustomers () {
      return axios
        .post('/fetch_customers', {
				token: axios.defaults.headers.common.Authorization
			})
        .then(({ data }) => {
          return data;
        })
    },
	
	getAuthorization () {
      return axios.defaults.headers.common.Authorization;
    },

    logout ({ commit }) {
      commit('clearUserData')
    }
  },

  getters : {
    isLogged: state => !!state.user
  }
})